/*Este componente NotFound representa una página de error 404,
 utilizada cuando una ruta no es encontrada en la aplicación. */

import { Col, Row } from "antd";
import "./NotFound.scss";
import Logo from "../../assets/logos/logoUni404.svg";
import Icon from "../../assets/icons/icon404.svg";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    const accessToken = sessionStorage.getItem("newCheckToken");
    if (accessToken) return navigate("/:userId");
    navigate("/exit");
  };

  return (
    <div className="not-found-container">
      <div className="blue-container">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
      </div>

      <div className="white-container">
        <Row gutter={[16, 16]}>
          <Col span={24}>
          <div className="icon">
              <img src={Icon} alt="icon-404" />
              </div>
          </Col>
          <Col span={24}>
          <p className="not-found">404  </p>
          </Col>
          <Col span={24}>
            <p className="texto">
            ¡Todo bien, solo un pequeño tropiezo! Vamos a intentarlo nuevamente para continuar.
            </p>
          </Col>
          <Col span={24}>
            <button
              style={{ marginTop: "56px" }}
              className="big-button"
              onClick={handleNavigate}
            >
              Aceptar
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NotFound;
